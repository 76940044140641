import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6133a2cc = () => interopDefault(import('../pages/add.vue' /* webpackChunkName: "pages/add" */))
const _46b23357 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _cc031c64 = () => interopDefault(import('../pages/discover.vue' /* webpackChunkName: "pages/discover" */))
const _1cc7cc86 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _356237f3 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _83eefd4a = () => interopDefault(import('../pages/foryou.vue' /* webpackChunkName: "pages/foryou" */))
const _0d34f9af = () => interopDefault(import('../pages/listing.vue' /* webpackChunkName: "pages/listing" */))
const _4509a140 = () => interopDefault(import('../pages/playground.vue' /* webpackChunkName: "pages/playground" */))
const _16c01292 = () => interopDefault(import('../pages/your-communities.vue' /* webpackChunkName: "pages/your-communities" */))
const _69d12ffa = () => interopDefault(import('../pages/communities/new.vue' /* webpackChunkName: "pages/communities/new" */))
const _2794cc3e = () => interopDefault(import('../pages/communities/recommended.vue' /* webpackChunkName: "pages/communities/recommended" */))
const _4ae6637a = () => interopDefault(import('../pages/communities/yours.vue' /* webpackChunkName: "pages/communities/yours" */))
const _9b9a25e6 = () => interopDefault(import('../pages/community/not-found.vue' /* webpackChunkName: "pages/community/not-found" */))
const _30888fe0 = () => interopDefault(import('../pages/communities/_id/index.vue' /* webpackChunkName: "pages/communities/_id/index" */))
const _2ff86a7c = () => interopDefault(import('../pages/community/_id/index.vue' /* webpackChunkName: "pages/community/_id/index" */))
const _238fc8cb = () => interopDefault(import('../pages/community/_id/oldindex.vue' /* webpackChunkName: "pages/community/_id/oldindex" */))
const _ac963040 = () => interopDefault(import('../pages/community/_id/post.vue' /* webpackChunkName: "pages/community/_id/post" */))
const _4f915af7 = () => interopDefault(import('../pages/community/_id/postold.vue' /* webpackChunkName: "pages/community/_id/postold" */))
const _1b9b754d = () => interopDefault(import('../pages/community/_id/_qid.vue' /* webpackChunkName: "pages/community/_id/_qid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/mb/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add",
    component: _6133a2cc,
    name: "add___id"
  }, {
    path: "/community",
    component: _46b23357,
    name: "community___id"
  }, {
    path: "/discover",
    component: _cc031c64,
    name: "discover___id"
  }, {
    path: "/en",
    component: _1cc7cc86,
    name: "index___en"
  }, {
    path: "/error",
    component: _356237f3,
    name: "error___id"
  }, {
    path: "/foryou",
    component: _83eefd4a,
    name: "foryou___id"
  }, {
    path: "/listing",
    component: _0d34f9af,
    name: "listing___id"
  }, {
    path: "/playground",
    component: _4509a140,
    name: "playground___id"
  }, {
    path: "/your-communities",
    component: _16c01292,
    name: "your-communities___id"
  }, {
    path: "/communities/new",
    component: _69d12ffa,
    name: "communities-new___id"
  }, {
    path: "/communities/recommended",
    component: _2794cc3e,
    name: "communities-recommended___id"
  }, {
    path: "/communities/yours",
    component: _4ae6637a,
    name: "communities-yours___id"
  }, {
    path: "/community/not-found",
    component: _9b9a25e6,
    name: "community-not-found___id"
  }, {
    path: "/en/add",
    component: _6133a2cc,
    name: "add___en"
  }, {
    path: "/en/community",
    component: _46b23357,
    name: "community___en"
  }, {
    path: "/en/discover",
    component: _cc031c64,
    name: "discover___en"
  }, {
    path: "/en/error",
    component: _356237f3,
    name: "error___en"
  }, {
    path: "/en/foryou",
    component: _83eefd4a,
    name: "foryou___en"
  }, {
    path: "/en/listing",
    component: _0d34f9af,
    name: "listing___en"
  }, {
    path: "/en/playground",
    component: _4509a140,
    name: "playground___en"
  }, {
    path: "/en/your-communities",
    component: _16c01292,
    name: "your-communities___en"
  }, {
    path: "/en/communities/new",
    component: _69d12ffa,
    name: "communities-new___en"
  }, {
    path: "/en/communities/recommended",
    component: _2794cc3e,
    name: "communities-recommended___en"
  }, {
    path: "/en/communities/yours",
    component: _4ae6637a,
    name: "communities-yours___en"
  }, {
    path: "/en/community/not-found",
    component: _9b9a25e6,
    name: "community-not-found___en"
  }, {
    path: "/en/communities/:id",
    component: _30888fe0,
    name: "communities-id___en"
  }, {
    path: "/en/community/:id",
    component: _2ff86a7c,
    name: "community-id___en"
  }, {
    path: "/en/community/:id/oldindex",
    component: _238fc8cb,
    name: "community-id-oldindex___en"
  }, {
    path: "/en/community/:id/post",
    component: _ac963040,
    name: "community-id-post___en"
  }, {
    path: "/en/community/:id/postold",
    component: _4f915af7,
    name: "community-id-postold___en"
  }, {
    path: "/en/community/:id/:qid",
    component: _1b9b754d,
    name: "community-id-qid___en"
  }, {
    path: "/communities/:id",
    component: _30888fe0,
    name: "communities-id___id"
  }, {
    path: "/community/:id",
    component: _2ff86a7c,
    name: "community-id___id"
  }, {
    path: "/community/:id/oldindex",
    component: _238fc8cb,
    name: "community-id-oldindex___id"
  }, {
    path: "/community/:id/post",
    component: _ac963040,
    name: "community-id-post___id"
  }, {
    path: "/community/:id/postold",
    component: _4f915af7,
    name: "community-id-postold___id"
  }, {
    path: "/community/:id/:qid",
    component: _1b9b754d,
    name: "community-id-qid___id"
  }, {
    path: "/",
    component: _1cc7cc86,
    name: "index___id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
